<template>
  <div>

    <div class="Login" v-if="languageSelect==='zh'">
      <div class="header">
        <div class="centerWidth">
          <div class="left">
            <router-link to="/" class="login">
              <img loading="lazy" src="../assets/logo.png" alt="" />
            </router-link>
            <div class="name">完善基本信息</div>
          </div>
          <div class="right" @click="outLogin()">退出登录</div>
        </div>
      </div>
      <div class="center">
        <div class="centerWidth">
          <div class="title">
            <img loading="lazy" src="@/assets/basicInfo.png" alt="" />
            填写基础信息完成注册
          </div>
          <el-form
              :label-position="labelPosition"
              label-width="100px"
              :model="form"
          >
            <el-form-item label="姓名" required>
              <el-input
                  v-model="form.realname"
                  placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别" required>
              <el-select v-model="form.sex" placeholder="请选择性别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出生年月" required>
              <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所在行业" required>
              <el-input
                  v-model="form.industry"
                  placeholder="请输入所在行业"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属公司" required>
              <el-input
                  v-model="form.company"
                  placeholder="请输入所属公司"
              ></el-input>
            </el-form-item>
            <el-form-item label="职务" required>
              <el-input v-model="form.duty" placeholder="请输入职务"></el-input>
            </el-form-item>
            <el-form-item label="阅读兴趣" required>
              <el-input
                  v-model="form.interest"
                  placeholder="请输入阅读兴趣"
              ></el-input>
            </el-form-item>
            <div class="submit" @click="submit()">确认</div>
          </el-form>
        </div>
      </div>
      <SimpleBottom msg="Welcome to Your Vue.js App" />
    </div>
    <div class="Login" v-if="languageSelect==='en'">
      <div class="header">
        <div class="centerWidth">
          <div class="left">
            <router-link to="/" class="login">
              <img loading="lazy" src="../assets/logo.png" alt="" />
            </router-link>
            <div class="name">Improve basic information</div>
          </div>
          <div class="right" @click="outLoginEn()">Log out</div>
        </div>
      </div>
      <div class="center">
        <div class="centerWidth">
          <div class="title">
            <img loading="lazy" src="@/assets/basicInfo.png" alt="" />
            Fill in the basic information to complete the registration
          </div>
          <el-form
              :label-position="labelPosition"
              label-width="100px"
              :model="form"
          >
            <el-form-item label="Name" required>
              <el-input
                  v-model="form.realname"
                  placeholder="Please enter your name"
              ></el-input>
            </el-form-item>
            <el-form-item label="Sex" required>
              <el-select v-model="form.sex" placeholder="Please enter your sex">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.labelEn"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Date of birth" required>
              <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  placeholder="Select date"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Industry" required>
              <el-input
                  v-model="form.industry"
                  placeholder="Please enter your industry"
              ></el-input>
            </el-form-item>
            <el-form-item label="Company" required>
              <el-input
                  v-model="form.company"
                  placeholder="Please enter your company"
              ></el-input>
            </el-form-item>
            <el-form-item label="Job" required>
              <el-input v-model="form.duty" placeholder="Please enter your job"></el-input>
            </el-form-item>
            <el-form-item label="Reading interest" required>
              <el-input
                  v-model="form.interest"
                  placeholder="Please enter your reading interest"
              ></el-input>
            </el-form-item>
            <div class="submit" @click="submitEn()">Confirm</div>
          </el-form>
        </div>
      </div>
      <SimpleBottom msg="Welcome to Your Vue.js App" />
    </div>
  </div>
</template>

<script>
import SimpleBottom from "@/components/SimpleBottom.vue";
import { userUpdate, logOut } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
export default {
  name: "BasicInformation",
  components: { SimpleBottom },
  data() {
    return {
      languageSelect:'',
      labelPosition: "left",
      options: [
        {
          value: "1",
          label: "男",
          labelEn: "Boy",
        },
        {
          value: "2",
          label: "女",
          labelEn: "Girl"
        },
      ],
      form: []
    };
  },
  computed: {},
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    submit() {
      if (!this.form.realname) {
        this.$message.error("请输入姓名");
        return false;
      }
      if (!this.form.sex) {
        this.$message.error("请选择性别");
        return false;
      }
      if (!this.form.birthday) {
        this.$message.error("请选择出生年月");
        return false;
      }
      if (!this.form.industry) {
        this.$message.error("请输入所在行业");
        return false;
      }
      if (!this.form.company) {
        this.$message.error("请输入所属公司");
        return false;
      }
      if (!this.form.duty) {
        this.$message.error("请输入职务");
        return false;
      }
      if (!this.form.interest) {
        this.$message.error("请输入阅读兴趣");
        return false;
      }
      userUpdate(this.form).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            localStorage.setItem("typeInfo", 1);
            this.$store.state.typeInfo = 1;
            //跳转页面或执行方法
            this.$router.push("/");
          },
        });
      });
    },
    submitEn() {
      if (!this.form.realname) {
        this.$message.error("Please enter your name");
        return false;
      }
      if (!this.form.sex) {
        this.$message.error("Please enter your sex");
        return false;
      }
      if (!this.form.birthday) {
        this.$message.error("Please enter your date of birth");
        return false;
      }
      if (!this.form.industry) {
        this.$message.error("Please enter your industry");
        return false;
      }
      if (!this.form.company) {
        this.$message.error("Please enter your company");
        return false;
      }
      if (!this.form.duty) {
        this.$message.error("Please enter your job");
        return false;
      }
      if (!this.form.interest) {
        this.$message.error("Please enter your reading interest");
        return false;
      }
      userUpdate(this.form).then((res) => {
        console.log(res)
        this.$message({
          message: "Supplementary information succeeded", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            localStorage.setItem("typeInfo", 1);
            this.$store.state.typeInfo = 1;
            //跳转页面或执行方法
            this.$router.push("/");
          },
        });
      });
    },
    // 退出登录
    outLogin() {
      logOut().then(() => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("access_token");
        localStorage.removeItem("userId");
        localStorage.removeItem("typeInfo");
        this.$message({
          message: "退出登录成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    outLoginEn() {
      logOut().then(() => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("access_token");
        localStorage.removeItem("userId");
        localStorage.removeItem("typeInfo");
        this.$message({
          message: "Log out Successful！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: #fff;
  height: 72px;
  display: flex;
  align-items: center;
  .centerWidth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .login {
        width: 168px;
      }
      .name {
        margin-left: 25px;
        padding-left: 30px;
        font-size: 20px;
        color: var(--main-color);
        height: 22px;
        line-height: 22px;
        border-left: 1px solid var(--main-color);
      }
    }
    .right {
      color: var(--main-color);
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.center {
  min-height: calc(100vh - 82px - 72px);
  background: url(../assets/infoBg.png) left top no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .centerWidth {
    width: 900px;
    padding: 40px 0px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: var(--main-color);
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 56px;
      img {
        width: 30px;
        height: 24px;
        object-fit: contain;
        margin-right: 17px;
      }
    }

    .submit {
      width: 792px;
      height: 54px;
      line-height: 54px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      border-radius: 4px;
      background: var(--main-color);
      margin-left: 100px;
      cursor: pointer;
      margin-top: 40px;
    }
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    line-height: 52px;
    font-size: 16px;
  }
  .el-input .el-select__caret {
    font-size: 20px;
  }
  .el-input__inner {
    line-height: 50px;
    height: 50px;
    font-size: 16px;
  }
  .el-input__inner::placeholder {
    color: #bbbbbb;
  }

  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #dcdfe6;
  }
  .el-form-item {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 22px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .el-form-item__content {
    width: 800px;
    border: 1px solid #d0d0d0;
    font-size: 16px;
  }
  .el-input__prefix {
    left: auto;
    right: 5px;
    .el-input__icon.el-icon-date {
      font-size: 20px;
      transform: rotate(180deg);
      &:before {
        content: "\e6e1";
      }
    }
  }
  .el-input--prefix .el-input__inner {
    padding-left: 15px;
  }
}
input {
  border: none;
  outline: none;
}
</style>
